export type ReportsResponse = ReportItem[]

export interface ISocialCheckInput {
  name: string
  nickname: string
  city?: string
  state: string
  country: string
  company: string
  email: string
  highSchool: string
  college: string
  phone: string
  twitterUrl: string
  facebookUrl: string
  instagramUrl: string
  linkedInUrl: string
  tikTokUrl: string
  pinterestUrl: string
  ageOrDateOfBirth: string
  topics: string[]
  website: string
  industry: string
}

export interface IEnterpriceItem {
  credits: number
  id: string
  name: string
}
export interface IRequesterItem {
  email: string
  firstname: string
  id: string
  lastname: string
}
export enum ESocialCheckStatus {
  None = 'None',
  NotReleased = 'NotReleased',
  InQueue = 'InQueue',
  InProgress = 'InProgress',
  Complete = 'Complete',
  Error = 'Error',
  PendingDownloadPdf = 'PendingDownloadPdf',
  PendingReview = 'PendingReview',
  ErroPdf = 'ErrorPdf',
  Cancelled = 'Cancelled',
  PendingComplianceSigned = 'PendingComplianceSigned',
  PendingConfirmProfiles = 'PendingConfigProfiles',
  PendingToScrapProfiles = 'PendingToScrapProfiles',
  ScrapeInProgress = 'ScrapeInProgress',
  All = 'All',
}

export type ReportsStatus = keyof typeof ESocialCheckStatus

export interface ReportItem {
  enterprise: IEnterpriceItem
  status: ReportsStatus
  pdf: string
  inputs: ISocialCheckInput
  reportDate: string
  pdfViewed: boolean
  requester: IRequesterItem
  id: string
  subjectId: string
  AnalyzePeopleWithAnalyst: boolean
  pdfLanguage: string
  pdfCoreFindings: boolean
  pdfSocialMediaAccounts: boolean
  pdfSentimentAnalysis: boolean
  pdfFlaggedBehaviors: boolean
  pdfPositivePosts: boolean
  pdfNegativePosts: boolean
  pdfNeutralPosts: boolean
  pdfWebSearchPosts: boolean
  pdfFocusedTopics: boolean
  reportType: EReportType
}

export enum EReportType {
  AiAnalyst = 'ai_analyst_report',
  Ai = 'ai_report',
  AdverseMedia = 'adverse_media_report',
  Assited = 'assisted_report',
}

export interface BECrudResponse<T> {
  info: T
}

export interface ReportsRequest {
  status: ReportsStatus
}

export interface uploadPdfRequest {
  file: FormData
  socialCheckId: string
}

export interface uploadPdfResponse {
  info: {
    url: string
  }
}

export interface BackgroundCheckPdfRequest {
  id: string
}

export interface BackgroundCheckPdfResponse {
  message: string
  status: number
  info: {
    url: string
  }
}

export interface UserItem {
  id: string
  email: string
  password: string
  status: EUserStatusTypes
  role: EUserRoleTypes
  createAt: string
  updateAt: string
  enterprise: string
  firstname: string
  lastname: string
  phoneNumber: string
}

export type UserResponse = UserItem[]
export interface Usercrudresponse {
  message: string
  status: number
  info: UserResponse
}

export enum EUserStatus {
  active = 'active',
  pending = 'pending',
  blocked = 'blocked',
  pending_on_boarding = 'pending_on_boarding',
}

export type EUserStatusTypes = keyof typeof EUserStatus
export type EUserRoleTypes = keyof typeof EUserRole

export enum EUserRole {
  owner = 'owner',
  manager = 'manager',
  operational_user = 'operational_user',
  view_user = 'view_user',
}

export interface UserRolAndState {
  status: string
  role: string
  id: string
}

export interface ConfigurationItem {
  status: string[]
  enterpriseRoles: string[]
  staffRoles: string[]
  permissions: string[]
  creditTypes: {
    FIND_PEOPLE: string
    ANALYZE_PEOPLE: string
  }
  products: {
    FACIAL_MATCHING: string
    SOCIAL_ANALYSIS: string
  }
}
export interface ConfigurationCrudResponse {
  message: string
  status: number
  info: ConfigurationItem
}

export interface UpdateReportValueRequest {
  socialCheckId: string
  status: string
}

export interface regenerateReportRequest {
  id?: string
}

export interface changeReportPdfConfigurationRequest {
  subjectId: string
  pdfLanguage: string
  pdfCoreFindings: string
  pdfSocialMediaAccounts: string
  pdfSentimentAnalysis: string
  pdfFlaggedBehaviors: string
  pdfPositivePosts: string
  pdfNegativePosts: string
  pdfNeutralPosts: string
  pdfWebSearchPosts: string
  pdfFocusedTopics: string
}
