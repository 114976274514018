import { api } from '@/store/api'

import Env from '../../../env/env'

import type {
  ProfilesRequest,
  ProfilesResponse,
  UploadProfilesRequest,
} from './Scrape.types'

export const ScrapeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProfiles: builder.query<ProfilesResponse, ProfilesRequest>({
      query: (request) => ({
        url: `${Env.VITE_SCRAPE_API_URL}/api/v1/search/profiles/${request.subjectId}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      transformResponse: (response: any) => {
        console.log(response.data)
        return response.data.results
      },
    }),
    uploadProfiles: builder.mutation<void, UploadProfilesRequest>({
      query: (request) => ({
        url: `${Env.VITE_SCRAPE_API_URL}/api/v1/scrapers/profiles/${request.subjectId}`,
        method: 'POST',
        body: {
          profiles: request.profiles,
        },
      }),
    }),
  }),
})
export const { useGetProfilesQuery, useUploadProfilesMutation } = ScrapeApi
