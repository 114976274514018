import type {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
} from '@tanstack/react-table'
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { format, toZonedTime } from 'date-fns-tz'
import { ArrowDown, ArrowUp, More, Sort } from 'iconsax-react'
import { isEmpty } from 'ramda'
import * as React from 'react'
import { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { Chip } from '@/components/Chip/Chip'
import { ConfirmActionPopUp } from '@/components/ConfirmActionPopUp/ConfirmActionPopUp'
import { EmptyState } from '@/components/EmptyState/EmptyState'
import { Loader } from '@/components/Loader/Loader'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectLabel,
  SelectGroup,
  SelectSeparator,
} from '@/components/ui/select'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { AppRoutes } from '@/constants/appRoutes'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import { useUpdateReportValueMutation } from '@/store/api/Admin/Admin.query'
import { setReportInfo, setScrapeData } from '@/store/api/Admin/Admin.slice'
import {
  ESocialCheckStatus,
  type ReportsResponse,
} from '@/store/api/Admin/Admin.types'
import {
  clearTokens,
  setEditSubjectToken,
} from '@/store/api/Subjects/SubjectsGuards.slice'
import {
  calculateRemainingTime,
  calculateRemainingTimeInMinutes,
} from '@/utils/date'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

import { ReportSettingSheet } from './ReportSettingSheet'
import { SubjectInputsSheet } from './SubjectInputsSheet'

interface ErrorTableTypes {
  data: ReportsResponse
  isLoading: boolean
  handleFilterChange: (key: any) => void
  selectedFilter: string
}

export function ReportsTable({
  data,
  isLoading,
  handleFilterChange,
  selectedFilter,
}: ErrorTableTypes) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
  const [rowSelection, setRowSelection] = useState({})
  const [globalFilter, setGlobalFilter] = useState('')
  const [sortBy, setSortBy] = useState<string>('reportDate')
  const [isDesc, setIsDesc] = useState<boolean>(true)

  const onPressDowload = (data: any) => {
    window.open(data?.pdf, '_blank')
  }
  //Sort

  const sortedReportItems = useMemo(() => {
    if (!data || data.length === 0) return []

    const copyOfReports = [...data]

    return copyOfReports.sort((a, b) => {
      let sortResult = 0

      if (sortBy === 'reportDate') {
        const dateA = new Date(a.reportDate)
        const dateB = new Date(b.reportDate)
        sortResult = dateA.getTime() - dateB.getTime()
      } else if (sortBy === 'timeLeft') {
        const timeA = calculateRemainingTimeInMinutes(new Date(a.reportDate))
        const timeB = calculateRemainingTimeInMinutes(new Date(b.reportDate))
        sortResult = timeA - timeB
      }

      return isDesc ? -sortResult : sortResult
    })
  }, [data, sortBy, isDesc])

  const toggleSortOrder = (column: string) => {
    if (sortBy === column) {
      setIsDesc(!isDesc)
    } else {
      setSortBy(column)
      setIsDesc(true)
    }
  }

  //Status Counts

  const statusCounts = useMemo(() => {
    if (!data) return {}

    const counts: any = {
      InQueue: 0,
      Complete: 0,
      PendingReview: 0,
      ErrorPdf: 0,
      PendingComplianceSigned: 0,
      NotReleased: 0,
      Cancelled: 0,
      PendingToScrapProfiles: 0,
      error: 0,
      ScrapeInProgress: 0,
      PendingConfirmProfiles: 0,
    }

    data.map((report) => {
      if (report.status in counts) {
        counts[report.status] += 1
      }
    })

    return counts
  }, [data])

  const copyToClipboard = async (text: string) => {
    // Verifica si la API del portapapeles está disponible
    if (navigator.clipboard && window.isSecureContext) {
      // Usa la API del portapapeles
      toast.success('subject ID copied')
      return navigator.clipboard
        .writeText(text)
        .then(() => {
          console.log('Texto copiado al portapapeles:', text)
        })
        .catch((err) => {
          console.error('Error al copiar al portapapeles:', err)
        })
    } else {
      // Fallback para navegadores que no soportan la API del portapapeles
      const textArea = document.createElement('textarea')
      textArea.value = text
      textArea.style.position = 'fixed' // Evita que se desplace la página
      document.body.appendChild(textArea)
      textArea.select() // Selecciona el texto
      document.execCommand('copy') // Copia el texto al portapapeles
      document.body.removeChild(textArea) // Elimina el elemento temporal
      toast.success('subject ID copied')
    }
  }

  //Update Report Status

  const [updateReportValue, { isLoading: isLoadingUpdateReportValue }] =
    useUpdateReportValueMutation()

  const handleUpdateReportValue = async (socialCheckId: string) => {
    try {
      const updateReportValueRequest = {
        socialCheckId: socialCheckId,
        status: selectedFilter === 'Complete' ? 'PendingReview' : 'Complete',
      }
      await updateReportValue(updateReportValueRequest).unwrap()
      toast.success('Value updated.')
    } catch (e) {
      toast.error('Error updating value.')
      catchWithSentry(e)
    }
  }

  //edit report

  const handleEditReport = (data: any) => {
    dispatch(
      setReportInfo({
        id: data.id,
        subjectId: data.subjectId,
        pdfUrl: data.pdf,
        pdfLanguage: data.pdfLanguage,
        pdfCoreFindings: data.pdfCoreFindings,
        pdfSocialMediaAccounts: data.pdfSocialMediaAccounts,
        pdfSentimentAnalysis: data.pdfSentimentAnalysis,
        pdfFlaggedBehaviors: data.pdfFlaggedBehaviors,
        pdfPositivePosts: data.pdfPositivePosts,
        pdfNegativePosts: data.pdfNegativePosts,
        pdfNeutralPosts: data.pdfNeutralPosts,
        pdfWebSearchPosts: data.pdfWebSearchPosts,
        pdfFocusedTopics: data.pdfFocusedTopics,
      }),
    )
    dispatch(clearTokens())
    dispatch(setEditSubjectToken(true))
    navigate(AppRoutes.socialMedia)
  }

  const handleScrapeData = (data: any) => {
    dispatch(
      setScrapeData({
        id: data.id,
        subjectId: data.subjectId,
        pdfUrl: data.pdf,
        pdfLanguage: data.pdfLanguage,
        pdfCoreFindings: data.pdfCoreFindings,
        pdfSocialMediaAccounts: data.pdfSocialMediaAccounts,
        pdfSentimentAnalysis: data.pdfSentimentAnalysis,
        pdfFlaggedBehaviors: data.pdfFlaggedBehaviors,
        pdfPositivePosts: data.pdfPositivePosts,
        pdfNegativePosts: data.pdfNegativePosts,
        pdfNeutralPosts: data.pdfNeutralPosts,
        pdfWebSearchPosts: data.pdfWebSearchPosts,
        pdfFocusedTopics: data.pdfFocusedTopics,
      }),
    )
    navigate(AppRoutes.scrapeData)
  }

  const columns: ColumnDef<any>[] = [
    {
      accessorKey: 'requester.firstname',
      header: 'Request By',
      id: 'requestBy',
      cell: ({ row }) =>
        `${row.original.requester.firstname || 'Nameless'} ${
          row.original.requester.lastname || ''
        }`,
    },
    {
      accessorKey: 'enterprise.name',
      header: 'Enterprise',
      id: 'enterprise',
      cell: ({ row }) => row.original.enterprise.name || 'Without enterprise',
    },
    {
      accessorKey: 'reportDate',
      header: () => (
        <div className="flex items-center gap-1">
          <p>Date</p>
          <Button
            onClick={() => toggleSortOrder('reportDate')}
            className="flex border-none shadow-none w-6 p-0"
            size={'icon'}
            variant={'outline'}
          >
            <ArrowUp
              size={16}
              color={weCheckPalette.neutralColor}
              className="mr-[-2.5px]"
            />

            <ArrowDown
              size={16}
              color={weCheckPalette.neutralColor}
              className="ml-[-2.5px]"
            />
          </Button>
        </div>
      ),
      cell: ({ row }) => {
        const date = new Date(row.original.reportDate)
        const zonedDate = toZonedTime(date, 'GMT')
        return format(zonedDate, 'MM/dd/yyyy - HH:mm', { timeZone: 'GMT' })
      },
    },
    {
      accessorKey: 'timeLeft',
      header: () => (
        <div className="flex items-center gap-1">
          <p>Time Left</p>
          <Button
            onClick={() => toggleSortOrder('timeLeft')}
            className={`flex border-none shadow-none w-6`}
            size={'icon'}
            variant={'outline'}
          >
            <ArrowUp
              size={16}
              color={weCheckPalette.neutralColor}
              className="mr-[-2.5px]"
            />

            <ArrowDown
              size={16}
              color={weCheckPalette.neutralColor}
              className="ml-[-2.5px]"
            />
          </Button>
        </div>
      ),
      cell: ({ row }) => {
        const reportDate = new Date(row.original.reportDate)
        const zonedDate = toZonedTime(reportDate, 'GMT')
        const hoursLeft = calculateRemainingTime(zonedDate)
        if (hoursLeft !== '0h 0m') {
          return `${hoursLeft}`
        } else
          return (
            <div className="w-min">
              <Chip
                isTag
                red={row.original.status !== 'Complete'}
                green={row.original.status === 'Complete'}
                text={
                  row.original.status !== 'Complete' ? 'Expired' : 'Delivered'
                }
              />
            </div>
          )
      },
    },
    {
      accessorKey: 'inputs.name',
      header: 'Subject',
      id: 'subject',
      cell: ({ row }) => row.original.inputs.name || 'Nameless',
    },
    {
      accessorKey: 'reportType',
      header: 'Report Type',
      id: 'reportType',

      cell: ({ row }) =>
        row.original.reportType === 'ai_analyst_report'
          ? 'AI + ANALYST'
          : row.original.reportType === 'ai_report'
            ? 'AI'
            : row.original.reportType === 'adverse_media_report'
              ? 'ADVERSE MEDIA'
              : row.original.reportType === 'assisted_report'
                ? 'ASSISTED'
                : row.original.reportType
                  ? row.original.reportType
                  : 'sin type',
    },
    {
      accessorKey: 'status',
      header: 'Status',
      id: 'status',
      cell: ({ row }) => (
        <div className="w-min">
          <Chip
            isTag
            text={
              row.original.status === 'InQueue'
                ? 'In Queue'
                : row.original.status === 'Complete'
                  ? 'Complete'
                  : row.original.status === 'PendingReview'
                    ? 'Pending Review'
                    : row.original.status === 'PendingComplianceSigned'
                      ? 'Pending Compliance Signed'
                      : row.original.status === 'ErrorPdf'
                        ? 'Error'
                        : row.original.status === 'NotReleased'
                          ? 'Not Released'
                          : row.original.status === 'PendingToScrapProfiles'
                            ? 'Pending To Scrap'
                            : row.original.status === 'ScrapeInProgress'
                              ? 'Scrape In Progress'
                              : row.original.status
            }
            blue={row.original.status === 'PendingReview'}
            green={row.original.status === 'Complete'}
            yellow={row.original.status === 'InQueue'}
            red={row.original.status === 'ErrorPdf'}
            primaryBlue={row.original.status === 'PendingComplianceSigned'}
          />
        </div>
      ),
    },
    {
      accessorKey: 'subjectId',
      header: 'SubjectID',
      id: 'SubjectID',
      cell: ({ row }) => {
        const subjectId = row.original.subjectId
        return subjectId?.length > 10
          ? `${subjectId?.slice(0, 10)}...`
          : subjectId
      },
    },
    {
      id: 'actions',
      cell: ({ row }) => (
        <div className="flex justify-end">
          <Select>
            <SelectTrigger
              className="w-10 h-10 border-none shadow-none p-1 "
              hiddenArrow
            >
              <More color={weCheckPalette.neutralColor} size={30} />
            </SelectTrigger>
            <SelectContent className="w-[224px]">
              <SelectGroup>
                <SelectLabel>{row.original.inputs.name}</SelectLabel>
                <SelectSeparator />
                {(row.original.status === 'Complete' ||
                  row.original.status === 'PendingReview') && (
                  <Button
                    variant={'outline'}
                    className="border-none shadow-none p-0 w-full"
                    onClick={() => handleEditReport(row.original)}
                  >
                    <p className="relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-2  outline-none focus:bg-primaryColor5 focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50 h-[35px] text-neutralColor60 text-sm font-medium tracking-tight hover:bg-primaryColor5 text-left">
                      Edit
                    </p>
                  </Button>
                )}
                {(row.original.status === 'Complete' ||
                  row.original.status === 'PendingReview') && (
                  <Button
                    variant={'outline'}
                    className="border-none shadow-none p-0 w-full"
                    onClick={() => onPressDowload(row.original)}
                  >
                    <p className="relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-2  outline-none focus:bg-primaryColor5 focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50 h-[35px] text-neutralColor60 text-sm font-medium tracking-tight hover:bg-primaryColor5 text-left">
                      Dowload PDF
                    </p>
                  </Button>
                )}

                {(row.original.status ===
                  ESocialCheckStatus.PendingToScrapProfiles ||
                  row.original.status ===
                    ESocialCheckStatus.ScrapeInProgress) && (
                  <Button
                    variant={'outline'}
                    className="border-none shadow-none p-0 w-full"
                    onClick={() => handleScrapeData(row.original)}
                  >
                    <p className="relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-2  outline-none focus:bg-primaryColor5 focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50 h-[35px] text-neutralColor60 text-sm font-medium tracking-tight hover:bg-primaryColor5 text-left">
                      Scrape data
                    </p>
                  </Button>
                )}
                <SubjectInputsSheet data={row.original} />
                <ReportSettingSheet data={row.original} />
                <ConfirmActionPopUp
                  TriggerText={`Set as
                    ${
                      row.original.status === 'Complete'
                        ? ' Pending Review'
                        : ' Complete'
                    }`}
                  Title={'Complete Report Confirmation'}
                  Description={
                    'You are about to mark this report as complete. A notification will be sent to the requester of the report. Please confirm that all information is accurate before proceeding.'
                  }
                  ConfirmText={
                    'I confirm that the report is ready for completion and a notification will be sent.'
                  }
                  onClick={async () => handleUpdateReportValue(row.original.id)}
                  isLoading={isLoadingUpdateReportValue}
                  customTriggerStyle="relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-2 pr-8 outline-none focus:bg-primaryColor5 focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50 h-[35px] p-2 text-neutralColor60 text-sm font-medium tracking-tight hover:bg-primaryColor5"
                />
                <Button
                  variant={'outline'}
                  className="border-none shadow-none p-0 w-full"
                  onClick={async () => copyToClipboard(row.original.subjectId)}
                >
                  <p className="relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-2  outline-none focus:bg-primaryColor5 focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50 h-[35px] text-neutralColor60 text-sm font-medium tracking-tight hover:bg-primaryColor5 text-left">
                    Copy Subject Id
                  </p>
                </Button>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      ),
    },
  ]

  const table = useReactTable({
    data: sortedReportItems,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      globalFilter,
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  })

  if (isLoading && !isLoadingUpdateReportValue) {
    return (
      <div className="flex h-96 items-center">
        <div className="flex justify-center w-full">
          <Loader />
        </div>
      </div>
    )
  }

  if (isEmpty(sortedReportItems)) {
    return (
      <div className="flex flex-col gap-10 justify-center p-5">
        <div className="flex items-center justify-end">
          <Select onValueChange={handleFilterChange} value={selectedFilter}>
            <SelectTrigger
              className="w-10 h-10 bg-primaryColor p-2 rounded-lg"
              hiddenArrow
            >
              <Sort color={weCheckPalette.staticWhite} />
            </SelectTrigger>
            <SelectContent className="w-[224px]">
              <SelectGroup>
                <SelectLabel>Filter by</SelectLabel>
                <SelectSeparator />

                <SelectItem value="All">All</SelectItem>
                <SelectItem value="InQueue">In Queue</SelectItem>
                <SelectItem value="PendingReview">Pending Review</SelectItem>
                <SelectItem value="Complete">Complete</SelectItem>
                <SelectItem value="ErrorPdf">Error</SelectItem>
                <SelectItem value="PendingComplianceSigned">
                  Pending Signed
                </SelectItem>
                <SelectItem value="PendingToScrapProfiles">
                  Pending To Scrap
                </SelectItem>
                <SelectItem value="ScrapeInProgress">
                  Scrape In Progress
                </SelectItem>
                <SelectItem value="PendingConfigProfiles">
                  Pending Confirm Profiles
                </SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <div className="flex items-center justify-center">
          <EmptyState
            text={`Here we will show the ${
              selectedFilter === 'InQueue'
                ? 'In Queue'
                : selectedFilter === 'Complete'
                  ? 'Complete'
                  : selectedFilter === 'PendingReview'
                    ? 'Pending Review'
                    : selectedFilter === 'PendingComplianceSigned'
                      ? 'Pending Compliance Signed'
                      : selectedFilter === 'ScrapeInProgress'
                        ? 'Scrape In Progress'
                        : selectedFilter === 'PendingToScrap'
                          ? 'Pending To Scrap'
                          : selectedFilter === 'PendingConfigProfiles'
                            ? 'Pending Confirm Profiles'
                            : 'Error'
            } reports when there are any`}
          />
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col w-full p-4 max-xl:gap-4 gap-6">
      <div className="flex items-center py-4 mb-2 justify-between">
        <Input
          placeholder="Filter by any element..."
          value={globalFilter}
          onChange={(event) => {
            const value = event.target.value
            setGlobalFilter(value)
          }}
          className="h-10 border border-neutralColor30 rounded-lg w-[376px]"
        />
        <Select onValueChange={handleFilterChange} value={selectedFilter}>
          <SelectTrigger
            className="w-10 h-10 bg-primaryColor p-2 rounded-lg"
            hiddenArrow
          >
            <Sort color={weCheckPalette.staticWhite} />
          </SelectTrigger>
          <SelectContent className="w-[224px]">
            <SelectGroup>
              <SelectLabel>Filter by</SelectLabel>
              <SelectSeparator />

              <SelectItem value="All">All</SelectItem>
              <SelectItem value="InQueue">In Queue</SelectItem>
              <SelectItem value="PendingReview">Pending Review</SelectItem>
              <SelectItem value="Complete">Complete</SelectItem>
              <SelectItem value="ErrorPdf">Error</SelectItem>
              <SelectItem value="PendingComplianceSigned">
                Pending Signed
              </SelectItem>
              <SelectItem value="PendingToScrapProfiles">
                Pending To Scrap
              </SelectItem>
              <SelectItem value="ScrapeInProgress">
                Scrape In Progress
              </SelectItem>
              <SelectItem value="PendingConfigProfiles">
                Pending Confirm Profiles
              </SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && 'selected'}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    key={cell.id}
                    className="text-sm font-medium tracking-tighter"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns?.length}>
                <EmptyState text="There seem to be results for your search" />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <div className="flex items-center justify-between space-x-2">
        <div className="flex gap-5 flex-wrap">
          {(selectedFilter === ESocialCheckStatus.InQueue ||
            selectedFilter === 'All') && (
            <Chip isTag yellow text={`In Queue ${statusCounts.InQueue || 0}`} />
          )}
          {(selectedFilter === ESocialCheckStatus.Complete ||
            selectedFilter === 'All') && (
            <Chip isTag green text={`Complete ${statusCounts.Complete || 0}`} />
          )}
          {(selectedFilter === ESocialCheckStatus.PendingReview ||
            selectedFilter === ESocialCheckStatus.All) && (
            <Chip
              isTag
              blue
              text={`Pending Review ${statusCounts.PendingReview || 0}`}
            />
          )}
          {(selectedFilter === ESocialCheckStatus.ErroPdf ||
            selectedFilter === 'All') && (
            <Chip isTag red text={`Error PDF ${statusCounts.ErrorPdf || 0}`} />
          )}
          {(selectedFilter === ESocialCheckStatus.Error ||
            selectedFilter === 'All') && (
            <Chip isTag red text={`Error ${statusCounts.error || 0}`} />
          )}
          {(selectedFilter === ESocialCheckStatus.PendingComplianceSigned ||
            selectedFilter === ESocialCheckStatus.All) && (
            <Chip
              isTag
              primaryBlue
              text={`Pending Compliance Signed ${
                statusCounts.PendingComplianceSigned || 0
              }`}
            />
          )}
          {selectedFilter === ESocialCheckStatus.All && (
            <Chip
              isTag
              text={`Not Released ${statusCounts.NotReleased || 0}`}
            />
          )}
          {selectedFilter === ESocialCheckStatus.All && (
            <Chip isTag text={`Cancelled ${statusCounts.Cancelled || 0}`} />
          )}

          {(selectedFilter === ESocialCheckStatus.All ||
            selectedFilter === ESocialCheckStatus.PendingToScrapProfiles) && (
            <Chip
              isTag
              text={`Pending to Scrap ${
                statusCounts.PendingToScrapProfiles || 0
              }`}
            />
          )}
          {(selectedFilter === ESocialCheckStatus.All ||
            selectedFilter === ESocialCheckStatus.ScrapeInProgress) && (
            <Chip
              isTag
              text={`Scrape In Progress ${statusCounts.ScrapeInProgress || 0}`}
            />
          )}
          {(selectedFilter === ESocialCheckStatus.All ||
            selectedFilter === ESocialCheckStatus.PendingConfirmProfiles) && (
            <Chip
              isTag
              text={`Pending Confirm Profiles ${
                statusCounts.PendingConfirmProfiles || 0
              }`}
            />
          )}
        </div>
        <div className="flex space-x-2">
          <Button
            className="h-10 px-4 py-2 rounded-lg border border-primary30 bg-primaryColor5"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <p className="text-neutralColor text-base font-normal">Previous</p>
          </Button>
          <Button
            className="h-10 px-4 py-2 rounded-lg border border-primary30 bg-primaryColor5"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <p className="text-neutralColor text-base font-normal">Next</p>
          </Button>
        </div>
      </div>
    </div>
  )
}
